<template>
  <div class="body">
    <el-container class="container">
      <el-header height="45px">
        <img src="@/assets/iqiyi_top.png" style="margin-top:-2px;margin-left:-1px;" />
      </el-header>
      <el-container>
        <el-aside width="590px">
          <el-container>
            <el-main style="padding:0;height:533px;background-color:#F2F5FA;overflow-y:auto;">
              <div style="height:980px"></div>
              <img src='@/assets/iqiyi_title.png' />
              <img src='@/assets/iqiyi_title2.png' />
              <div style="width:100%;text-align:left;">
                <img src="@/assets/iqiyi_icon.png" style="float:left" />
                <div class="service_dialog" @click="dialogshow4 = true" v-html="service3_html"></div>
              </div>
              <div style="width:100%;text-align:right;">
                <div class="avtar_dialog" @click="dialogshow1 = true" v-html="user_html"></div>
                <img src="@/assets/iqiyi_avtar.png" style="float:right" />
              </div>
              <div style="width:100%;text-align:left;">
                <img src="@/assets/iqiyi_icon.png" style="float:left" />
                <div class="service_dialog" @click="dialogshow2 = true" v-html="service_html"></div>
              </div>
              <div style="width:100%;text-align:right;">
                <div class="avtar_dialog" @click="dialogshow6 = true" v-html="user_html2"></div>
                <img src="@/assets/iqiyi_avtar.png" style="float:right" />
              </div>
              <div style="width:100%;text-align:left;">
                <img src="@/assets/iqiyi_icon.png" style="float:left" />
                <div class="service_dialog" @click="dialogshow3 = true" v-html="service2_html"></div>
              </div>
              <div style="width:100%;text-align:right;">
                <div class="avtar_dialog" @click="dialogshow7 = true" v-html="user_html3"></div>
                <img src="@/assets/iqiyi_avtar.png" style="float:right" />
              </div>
              <div style="width:100%;text-align:left;">
                <img src="@/assets/iqiyi_icon.png" style="float:left" />
                <div class="service_dialog" @click="dialogshow5 = true" v-html="service4_html"></div>
              </div>
            </el-main>
            <el-footer style="padding:0;" height="90px">
              <img src="@/assets/iqiyi_bottom.png" style="margin-top:-2px;" />
            </el-footer>
          </el-container>
        </el-aside>
        <el-main style="padding:0;"><img src="@/assets/iqiyi_aside.png" style="margin-top:-2px;" /></el-main>
      </el-container>
    </el-container>
    <el-dialog top="16vh" :visible.sync="dialogshow1" width="550px" title="用户对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入用户对话内容" v-model="user_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="user_confirm()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow6" width="550px" title="用户对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入用户对话内容" v-model="user_text2"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="user_confirm2()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow7" width="550px" title="用户对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入用户对话内容" v-model="user_text3"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="user_confirm3()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow4" width="550px" title="爱奇艺客服对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入爱奇艺客服对话内容" v-model="service3_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="service3_confirm()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow2" width="550px" title="爱奇艺客服对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入爱奇艺客服对话内容" v-model="service_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="service_confirm()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow3" width="550px" title="爱奇艺客服对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入爱奇艺客服对话内容" v-model="service2_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="service2_confirm()" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog top="16vh" :visible.sync="dialogshow5" width="550px" title="爱奇艺客服对话内容" center :before-close="handleClose">
      <el-input type="textarea" :rows="6" placeholder="请输入爱奇艺客服对话内容" v-model="service4_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="service4_confirm()" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "iqiyi",
  data() {
    return {
      user_text: "",
      user_text2: "",
      user_text3: "",
      service_text: "",
      service2_text: "",
      service3_text: "",
      service4_text: "",
      user_html: "",
      user_html2: "",
      user_html3: "",
      service_html: "",
      service2_html: "",
      service3_html: "",
      service4_html: "",
      dialogshow1: false,
      dialogshow2: false,
      dialogshow3: false,
      dialogshow4: false,
      dialogshow5: false,
      dialogshow6: false,
      dialogshow7: false,
    };
  },
  mounted() {

  },
  methods: {
    handleClose(done) {
      done();
    },
    user_confirm() {
      this.user_html = ""
      var user = this.user_text.split(/[(\r\n)\r\n]+/);
      for (let i in user) {
        this.user_html += "<p>" + user[i] + "</p>"
      }
      this.dialogshow1 = false;
    },
    user_confirm2() {
      this.user_html2 = ""
      var user = this.user_text2.split(/[(\r\n)\r\n]+/);
      for (let i in user) {
        this.user_html2 += "<p>" + user[i] + "</p>"
      }
      this.dialogshow6 = false;
    },
    user_confirm3() {
      this.user_html3 = ""
      var user = this.user_text3.split(/[(\r\n)\r\n]+/);
      for (let i in user) {
        this.user_html3 += "<p>" + user[i] + "</p>"
      }
      this.dialogshow7 = false;
    },
    service_confirm() {
      this.service_html = ""
      var service = this.service_text.split(/[(\r\n)\r\n]+/);
      for (let i in service) {
        this.service_html += "<p>" + service[i] + "</p>"
      }
      this.dialogshow2 = false;
    },
    service2_confirm() {
      this.service2_html = ""
      var service = this.service2_text.split(/[(\r\n)\r\n]+/);
      for (let i in service) {
        this.service2_html += "<p>" + service[i] + "</p>"
      }
      this.dialogshow3 = false;
    },
    service3_confirm() {
      this.service3_html = ""
      var service = this.service3_text.split(/[(\r\n)\r\n]+/);
      for (let i in service) {
        this.service3_html += "<p>" + service[i] + "</p>"
      }
      //console.log(this.service3_html)
      this.dialogshow4 = false;
    },
    service4_confirm() {
      this.service4_html = ""
      var service = this.service4_text.split(/[(\r\n)\r\n]+/);
      for (let i in service) {
        this.service4_html += "<p>" + service[i] + "</p>"
      }
      this.dialogshow5 = false;
    },
    clearborder() {
      this.dialogshow2 = true
    }
  },
};
</script>
<style lang="less" scoped>
body {
  width: 100%;
  background-color: #fff;
}

.set {
  color: #3743d6;
  cursor: pointer;
  width: 100px;
}

.active {
  background: #3743d6;
  color: #fff;
}

.el-header {
  padding: 0;
}

.container {
  border: 1px solid #e5e5e5;
  height: 670px;
  width: 925px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.el-main {
  position: relative;
  height: 100%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0;
  border-left: 1px solid #e2e2e2;
  overflow: initial;
}

.el-input {
  width: 300px;
  margin-left: 20px;
}

.body {
  background-color: #fff;
  width: 100%;
  height: 930px;
  position: absolute;
}

.avtar_dialog {
  min-width: 100px;
  max-width: 220px;
  min-height: 40px;
  background-color: #F9E5C5;
  display: inline-block;
  margin-top: 10px;
  border-radius: 4px 4px 15px 15px;
  color: #C8A06A;
  text-align: left;
  line-height: 25px;
  font-size: 14px;
  padding: 10px 14px;
  font-family: "\5FAE\8F6F\96C5\9ED1", "\5B8B\4F53", Arial, Helvetica, sans-serif;
  cursor: pointer;
  position: relative;
}

.service_dialog {
  min-width: 100px;
  max-width: 460px;
  min-height: 40px;
  background-color: #fff;
  display: inline-block;
  margin-top: 15px;
  border-radius: 4px 4px 15px 15px;
  color: #333;
  text-align: left;
  line-height: 25px;
  font-size: 14px;
  padding: 10px 14px;
  font-family: "\5FAE\8F6F\96C5\9ED1", "\5B8B\4F53", Arial, Helvetica, sans-serif;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgb(200, 200, 200, 0.1)
}

.vip {
  position: absolute;
  width: 11px;
  height: 11px;
  display: block;
}

.avtar_dialog:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -5px;
  left: -5px;
  background-image: url("../../assets/iqiyi_vip_crown.png");
  background-size: cover;
}
</style>